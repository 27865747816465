import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getTimeLog, createTimeLog } from "../api";


export const useGetTimeLog = () => {
  return useQuery(
    ['useGetTimeLog'],
    () => getTimeLog(),
    {
      staleTime: 1000 * 60 * 60,
      retry: false,
    },
  );
};

export const useCreateTimeLog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['useCreateTimeLog'],
    mutationFn: createTimeLog,
    onSuccess: (status, variables, context ) => {
      queryClient.invalidateQueries(
        ['useGetTimeLog'],
      );
    },
  });
};
