import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isExpired, decodeToken } from "react-jwt";
import * as _ from 'lodash';
import { startLogout,refreshToken } from '../redux/auth/thunks';
import { handleLogin } from '../redux/auth/authSlice';
import { useLocation } from 'react-router-dom';
import { createTimeLog } from "../api";
import moment from 'moment';


export const useCheckAuth = () => {
  const { status } = useSelector(state => state.auth);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const validateToken = async() => {
    const tokenStorage = await localStorage.getItem('token');

    if (!tokenStorage){
      return dispatch(startLogout());
    } 
      

    const tokenDecode = decodeToken(tokenStorage);
    const isMyTokenExpired = isExpired(tokenStorage);

    console.log(isMyTokenExpired);
    console.log(tokenStorage);

    if (isMyTokenExpired){
      handleSubmit();
      return dispatch(startLogout());
    }
    const payload = {
      token: tokenStorage,
      user: (!_.isUndefined(tokenDecode['user'])) ? tokenDecode['user'] : null,
    }

    return dispatch(handleLogin(payload));
    //return dispatch(refreshToken());
  };

  const handleSubmit = async () => {
    const defaultValues = {
      user_id: user.id,
      type: 'Out',
      register_time: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    await createTimeLog(defaultValues)
  };

  useEffect(() => {
    validateToken();
  }, []);

  return status;
}
