import { handleLogout, handleLogin, handleStatusAuth, handleSessionStatus } from './authSlice';
import { isExpired, decodeToken } from "react-jwt";

import { getEnvVariables } from '../../constants/getEnvVariables';
import * as _ from 'lodash';
import { createTimeLog } from "../../api";
import moment from 'moment';

const { API_URL } = getEnvVariables();


export const startLogin = (dataSend) => {
  return async (dispatch) => {
    
    try {
      dispatch(handleLogout());
      const respApi = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(dataSend)
      }).then( resp => resp.json() );
      

      if(respApi.code != 200){
        return dispatch(handleStatusAuth({ status: 'error', error: respApi.msg }));
      }
      const tokenDecode = decodeToken(respApi.data);
      const payload = {
        token: respApi.data,
        user: (!_.isUndefined(tokenDecode.user)) ? tokenDecode.user : null,
      }
      localStorage.setItem('token',  respApi.data);
      //console.log("ya seteo el token en el local storage");

      await createTimeLog({
        user_id: tokenDecode.user.id,
        type: 'In',
        register_time: moment().format('YYYY-MM-DD HH:mm:ss')
      });

      dispatch(handleLogin(payload));
    } catch (catchError) {
      if (_.isUndefined(catchError.message)) {
        return dispatch(handleStatusAuth({ status: 'error', error: 'Error Server' }));
      }
      return dispatch(handleStatusAuth({ status: 'error', error: catchError.message }));
    }
  }
}

export const forgotPassword = (dataSend) => {
  return async (dispatch) => {
    try {
      dispatch(handleStatusAuth());
      const respApi = await fetch(`${API_URL}/forgot-password`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(dataSend)
      }).then( resp => resp.json() );
      

      if(respApi.code != 200){
        return dispatch(handleStatusAuth({ status: 'error', error: respApi.msg }));
      }
      return dispatch(handleStatusAuth({ status: 'success'}));
    } catch (catchError) {
      if (_.isUndefined(catchError.message)) {
        return dispatch(handleStatusAuth({ status: 'error', error: 'Error Server' }));
      }
      return dispatch(handleStatusAuth({ status: 'error', error: catchError.message }));
    }
  }
}

export const resetPassword = (dataSend) => {
  return async (dispatch) => {
    try {
      dispatch(handleStatusAuth());
      const respApi = await fetch(`${API_URL}/reset-password`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(dataSend)
      }).then( resp => resp.json() );
      

      if(respApi.code != 200){
        return dispatch(handleStatusAuth({ status: 'error', error: respApi.msg }));
      }
      return dispatch(handleStatusAuth({ status: 'success'}));
    } catch (catchError) {
      if (_.isUndefined(catchError.message)) {
        return dispatch(handleStatusAuth({ status: 'error', error: 'Error Server' }));
      }
      return dispatch(handleStatusAuth({ status: 'error', error: catchError.message }));
    }
  }
}

export const activateAccount = (dataSend) => {
  return async (dispatch) => {
    try {
      dispatch(handleStatusAuth());

      const respApi = await fetch(`${API_URL}/account-confirm`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(dataSend)
      }).then( resp => resp.json() );
      

      if(respApi.code != 200){
        return dispatch(handleStatusAuth({ status: 'error', error: respApi.msg }));
      }
      return dispatch(handleStatusAuth({ status: 'success'}));
    } catch (catchError) {
      if (_.isUndefined(catchError.message)) {
        return dispatch(handleStatusAuth({ status: 'error', error: 'Error Server' }));
      }
      return dispatch(handleStatusAuth({ status: 'error', error: catchError.message }));
    }
  }
}

export const refreshToken = () => {
  return async (dispatch) => {
    try {
      dispatch(handleStatusAuth());
      const token = await localStorage.getItem('token');

      const respApi = await fetch(`${API_URL}/refresh`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).then( resp => resp.json() );
      

      if(respApi.code != 200){
        localStorage.removeItem('token');
        dispatch(handleLogout());
        return;
      }
      const tokenDecode = decodeToken(respApi.data);
      const payload = {
        token: respApi.data,
        user: (!_.isUndefined(tokenDecode.user)) ? tokenDecode.user : null,
      }
      localStorage.setItem('token',  respApi.data);
      dispatch(handleLogin(payload));
    } catch (catchError) {
      if (_.isUndefined(catchError.message)) {
        return dispatch(handleStatusAuth({ status: 'error', error: 'Error Server' }));
      }
      return dispatch(handleStatusAuth({ status: 'error', error: catchError.message }));
    }
  }
}

export const startLogout = () => {
  return async (dispatch) => {
    localStorage.removeItem('token');
    dispatch(handleLogout());
  }
}

export const setStatusAuth = () => {
  return async (dispatch) => {
    dispatch(handleStatusAuth());
  };
}

export const setSessionStatus = (status, lastConnection) => {
  return async (dispatch) => {
    dispatch(handleSessionStatus({status: status, lastConnection: lastConnection}));
  };
}