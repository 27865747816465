const locale = {
  English: 'Inglés',
	Spanish: 'Español',
  SignOut: 'Salir',
  Attendance: 'Asistencia',
  "Record saved successfully!": "¡Registro guardado exitosamente!",
  "Employee ID": "ID de Empleado",
  Name: "Nombre",
  "Register Entry": "Registrar Entrada",
  "Check Out": "Registrar Salida",
  "Close": "Cerrar",
};

export default locale;
