import React from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18next from '@/i18n';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { UserCheck, UserMinus } from 'lucide-react';
import { Skeleton} from 'antd';

import { useNotifications } from '@/context/NotificationContext';
import { useCreateTimeLog } from '@/hooks/useTimeLog';
import "../../tailw.css";

import en from './header-i18n/en';
import es from './header-i18n/es';
i18next.addResourceBundle('en', 'lang', en);
i18next.addResourceBundle('es', 'lang', es);

export const ModalAttendance = ({showModal, setShowModal}) => {
  const { t } = useTranslation('lang');
  const { successNotification, warningNotification } = useNotifications();
  const createData = useCreateTimeLog();
  const { user } = useSelector(state => state.auth);

   if(!user) return null;

  const handleSubmit = (type) => {
    const defaultValues = {
      user_id: user.id,
      type: type,
      register_time: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    createData.mutateAsync(defaultValues)
    .then((resp) => { 
      successNotification(t('Record saved successfully!'));
      hideModal();
    })
    .catch((error) => {
      warningNotification(error) 
    });
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <Modal size="md" show={showModal} style={{ zIndex: 1050}}>
      <Modal.Header>
        <Link to="#" as='span' className="d-flex ms-auto text-dark" onClick={() => { hideModal() }}><i className='fe fe-x ms-auto'></i></Link>
      </Modal.Header>
      <Modal.Body>
        <Skeleton loading={createData.isLoading } active>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t('Employee ID')}
                </label>
                <input type="text" id="employeeId" value={user.id} readOnly
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  {t('Name')}
                </label>
                <input type="text" id="name" value={user.person.pop_nombre} readOnly
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="flex space-x-4">
              <button onClick={() => handleSubmit('In')}
                className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <UserCheck className="h-5 w-5 mr-2" />
                {t('Register Entry')}
              </button>
              <button onClick={() => handleSubmit('Out')}
                className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <UserMinus className="h-5 w-5 mr-2" />
                {t('Check Out')}
              </button>
            </div>
          </div>
        </Skeleton>
        
      </Modal.Body>
      <Modal.Footer>
        <Button className='ripple' variant="secondary" onClick={() => { hideModal() }}>
          {t('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
