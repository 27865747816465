const locale = {
  English: 'English',
	Spanish: 'Spanish',
	SignOut: 'Sign Out',
  Attendance: 'Attendance',
  "Record saved successfully!": "Record saved successfully!",
  "Employee ID": "Employee ID",
  Name: "Name",
  "Register Entry": "Register Entry",
  "Check Out": "Check Out",
  "Close": "Close",
};

export default locale;
